@import "variables";

// Layout

.page {
  padding: 0 32px;
}

.page-content {
  max-width: 1240px;
  margin: 0 auto;

  &.narrow {
    max-width: 480px;
  }

  & > *:first-child {
    margin-top: 0;
  }
}

.box {
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14);

  & > *:first-child {
    margin-top: 0;
  }
}

.box-bordered {
  background-color: white;
  border-bottom: 4px solid $cyan2;

  & > *:first-child {
    margin-top: 0;
  }
}

.message {
  padding: 8px 16px;
  margin-bottom: 4px;
  border: 1px solid $grey3;
  background-color: white;
  border-radius: 4px;

  display: flex;
  align-items: center;

  & > .message-content {
    flex-grow: 1;
  }

  & > .close {
    cursor: pointer;
    margin-left: 24px;
    font-size: 24px;
  }

  &.error {
    border-color: $red;
    background-color: $red-background;

    & > .message-content {
      color: $red;
    }
  }
}

.columns {
  display: flex;

  & > * {
    padding: 0 12px;
  }

  &.extra-pad > * {
    padding: 0 24px;
  }

  & > *:first-child {
    padding-left: 0;
  }

  & > *:last-child {
    padding-right: 0;
  }
}

.panel {
  & > .panel-header {
    background-color: $grey;
    border-bottom: 2px solid $cyan;

    & > *:first-child {
      margin-top: 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }
}

.dropdown-menu {
  position: absolute;
  display: none;
  background-color: white;
  box-shadow: 4px 6px 6px 1px rgba(0,0,0,0.25), 0 1px 3px 0 rgba(0,0,0,0.14);
  border: 1px solid $grey2;
  z-index: 100;
}
