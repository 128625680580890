/* You can add global styles to this file, and also import other style files */

@import "../../node_modules/normalize.css/normalize.css";
@import '../assets/fonts/source-sans-pro.css';

@import "../../node_modules/primeicons/primeicons.css";
@import "../../node_modules/primeng/resources/themes/nova/theme.css";
@import "../../node_modules/primeng/resources/primeng.min.css";

@import "variables";
@import "component";
@import "form";

* {
  box-sizing: border-box;
}

body {
  min-width: 768px;
  background-color: $grey;
  color: $default-font-color;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  line-height: 20px;
}

h1 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
}

h2 {
  font-size: 24px;
  line-height: 31px;
  font-weight: 600;
}

a,
.link {
  color: $grey6;
  cursor: pointer;
  text-decoration: underline;
}

.semi-bold {
  font-weight: 600;
}

.size-50 {
  font-size: 50px;
  line-height: 63px;
}

.size-38 {
  font-size: 38px;
  line-height: 47px;
}

.size-32 {
  font-size: 32px;
  line-height: 40px;
}

.size-28 {
  font-size: 28px;
  line-height: 36px;
}

.size-24 {
  font-size: 24px;
  line-height: 31px;
}

.size-21 {
  font-size: 21px;
  line-height: 27px;
}

.size-18 {
  font-size: 18px;
  line-height: 23px;
}

.size-16 {
  font-size: 16px;
  line-height: 20px;
}

.size-14 {
  font-size: 14px;
  line-height: 18px;
}

.size-12 {
  font-size: 12px;
  line-height: 15px;
}
