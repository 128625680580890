@import 'variables';

label {
  color: $grey6;
  font-size: 16px;

  &.checkbox {
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      flex-shrink: 0;
    }
  }
}

input {
  font-size: 24px;
  line-height: 45px;
  border: 1px solid $grey4;
  border-radius: 1px;
  padding: 0 8px;

  &:focus {
    border-color: $blue;
    box-shadow: 0 0 3px 0 $blue;
  }

  &.inline {
    font-size: 16px;
    line-height: 20px;
    border: none;
    background-color: #EDF0F2;
    padding: 8px 6px;

    &:focus {
      box-shadow: none;
    }
  }

  &[disabled] {
    background-color: $grey2;
  }
}

a.button {
  display: inline-block;
  text-align: center;
}

a.button,
button {
  background-color: $blue;
  border: 1px solid $blue2;
  color: white;
  border-radius: 1px;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 16px;
  cursor: pointer;
  box-shadow: 0 1px 0 0 lighten(black, 75%);

  &:hover,
  &:focus {
    background-color: $blue2;
    color: white;
  }

  &[disabled] {
    cursor: default;
    background-color: lighten($blue, 30%);
    border-color: lighten($blue2, 30%);
  }

  &.selected {
    background-color: $blue3;
    cursor: default;
  }

  &.secondary {
    background-color: $grey;
    border-color: $grey4;
    color: black;

    &:hover,
    &:focus {
      background-color: #EDF0F2;
    }

    &[disabled] {
      background-color: lighten($grey, 5%);
      border-color: lighten($grey4, 10%);
      color: lighten(black, 60%);
    }

    &.selected {
      background-color: $blue;
      color: white;
      border-color: $blue2;
    }
  }

  &.wide {
    padding: 12px 32px;
  }

  &.huge {
    padding: 32px 32px;
    font-size: 18px;
  }

  &.big {
    font-size: 18px;
    padding: 14px 32px;
  }

  &.small {
    padding: 8px 16px;
  }
}

.button-group {
  & > button {
    margin: 0 3px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

input[type="checkbox"] {
  appearance: none;
  display: inline-block;
  position: relative;
  cursor: pointer;
  border: 1px solid $grey4;
  background-color: white;
  border-radius: 1px;
  margin-right: 8px;
  width: 36px;
  height: 36px;

  &:checked:after {
    position: absolute;
    top: 0;
    font-size: 48px;
    content: '\2714';
    left: 3px;
    line-height: 28px;
  }

  &[disabled] {
    cursor: default;
    background-color: $grey2;
  }
}

form {
  .stacked-input {
    margin-bottom: 16px;

    & > label {
      display: block;
      margin: 4px 0;
    }

    & > input {
      display: block;
      width: 100%;
    }

    & > select {
      width: 100%;
    }

    .input-with-button {
      display: flex;

      & > input {
        flex-grow: 1;
        min-width: 0;
      }
    }
  }

  .buttons {
    margin-top: 24px;

    a {
      margin-left: 24px;
    }
  }
}

.disabled {
  color: $grey3;

  label {
    color: $grey3;
  }

  input[type="checkbox"] {
    cursor: default;
  }
}
