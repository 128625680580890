$default-font-color: #212529;

$grey: #F2F2F2;
$grey2: #DCDFE2;
$grey3: #C9C9C9;
$grey4: #C3C2C6;
$grey5: #606060;
$grey6: #303030;

$blue: #2F62AD;
$blue2: #255293;
$blue3: #1C4279;

$cyan: #83C1C7;
$cyan2: #45A2AB;

$red: #DC3545;
$red-background: #FFFBFB;

$thl-green: #7bc143;

$margin: 24px;
$margin-half: 12px;
